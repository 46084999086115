@import "../scss/variables";
/*------------------------------------------------------------------
[Dark-stylesheet Stylesheet]

Project        :   Rapo - HTML5 Bootstrap Admin Template
Version        :   V.1
Create Date    :   05/02/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/* ------ Table Of Contents
	** FONTS IMPORT
	** BOOTSTRAP FRAMEWORK
	** COMPONENT COLS & ROWS
	** TABLE STYLES
	** FROM ELEMENTS
	** BUTTON STYLES
	** DROPDOWN STYLES
	** INPUT GROUP
	** CUSTOM CONTROL
	** NAVIGATION
	** CARD STYLES
	** ACCORDION
	** BREADCRUMB
	** PAGINATION
	** BADGES
	** JUMBOTRON
	** ALERTS
	** LIST GROUP
	** TOAST & MODAL
	** TOOLTIP & POPOVER
	** CAROUSEL
	** DEFAULT ELEMENTS
	** DATE PICKER
	** WIZARD ELEMENTS
	** JQMAP
	** RANGE SLIDER
	** PERFECT SCROLLBAR
	** SELECT2
	** SPECTRUM
	** DATE PICKER
	** CUSTOM STYLES
	** BACKGROUNDS
	** BORDERS
	** HEIGHT
	** MARGIN
	** MARGIN & PADDING
	** POSITION & VALUES
	** TEXT SIZES & FONTS
	** CUSTOM CSS

	
/*---FONTS IMPORT --- */

body.dark-theme {
	color: $white;
	background-color: $dark-theme2 !important;
}
.dark-theme {
	.card {
		background: $dark-theme !important;
		border: 0px solid #353c65 !important;
		box-shadow: 0 5px 15px 5px rgba(28, 39, 60, 0.1);
	}
	.main-header {
		background: $dark-theme2;
		border-bottom: 0px solid #353c65;
		box-shadow: none;
		.main-header-search {
		}
	}
	.side-header {
		border-right: 0;
	}
	.main-header-message>a, .main-header-notification>a, .nav-item.full-screen>a, .card-title {
		color: $white;
	}
	.card-dashboard-audience-metrics {
		.card-header, .card-body {
			background-color: $dark-theme;
		}
	}
	.card-header {
		background-color: $dark-theme;
		border-bottom: 0;
	}
	.border-bottom {
		border-bottom: 1px solid rgba(234, 236, 241, 0.1) !important;
	}
	.border-top {
		border-top: 1px solid rgba(234, 236, 241, 0.1) !important;
	}
	.border-right {
		border-right: 1px solid rgba(234, 236, 241, 0.1) !important;
	}
	.border-left {
		border-left: 1px solid rgba(234, 236, 241, 0.1) !important;
	}
	.border {
		border: 1px solid rgba(234, 236, 241, 0.1) !important;
	}
	.table tbody tr, .table-bordered thead th {
		background-color: $dark-theme;
	}
	.account-background {
		background: url(../img/1.png) !important;
	}
}
.table-bordered thead td {
	background-color: $dark-theme;
}
.dark-theme {
	.wizard > .steps > ul::before{
		background: #30355d;
	}
	.table {
		color: $white-7;
	}
	.table-bordered {
		border: 1px solid rgba(234, 236, 241, 0.1) !important;
		th, td {
			border: 1px solid rgba(234, 236, 241, 0.1);
		}
	}
	.card-dashboard-audience-metrics .card-body h4 {
		color: #fcfcfd;
	}
	.progress {
		background-color: $white-1;
	}
	.card-dashboard-audience-metrics .flot-chart .flot-x-axis>div span:last-child {
		color: #b9c6de;
	}
	.main-footer {
		background-color: $dark-theme2;
		border-top: 0px solid rgba(226, 232, 245, 0.1);
	}
	.sidebar {
		&.sidebar-right {
			box-shadow: 5px 7px 26px -5px #030f2d !important;
		}
		.tabs-menu ul {
			border-bottom: 1px solid rgba(235, 234, 241, 0.1);
		}
	}
	.chat .contacts li {
		border-bottom: 1px solid rgba(227, 227, 247, 0.1);
	}
	.form-control {
		color: $white;
		background-color: $dark-theme;
		border: 1px solid rgba(226, 232, 245, 0.2);
		&:focus, &:hover {
			border: 1px solid rgba(226, 232, 245, 0.2);
		}
	}
	.list-group-item {
		background-color: $dark-theme;
		border: 1px solid rgba(231, 235, 243, 0.1);
		&+.list-group-item {
			border-top-width: 0 !important;
		}
	}
	.main-header-center .form-control {
		border-color: $white-1 !important;
		background-color: $white-1 !important;
	}
	.main-header {
		form[role="search"] {
			&.active input {
				background: $dark-theme;
			}
			button[type="reset"] {
				background: transparent;
			}
		}
		.input-group-btn .btn:hover i {
			color: $white;
		}
	}
	.main-header-notification .dropdown-menu {
		background-color: $dark-theme;
	}
	.notification-label {
		color: $white;
	}
	.main-notification-list a:hover, .main-message-list a:hover {
		background: $dark-theme3;
	}
	.nav .nav-item .dropdown-menu {
		-webkit-box-shadow: 0px 5px 15px 5px rgba(4, 17, 56,0.17);
		box-shadow: 0px 5px 15px 5px rgba(4, 17, 56,0.17);
	}
	.notification-subtext {
		color: $white-3;
	}
	.main-header-message .dropdown-footer, .main-header-notification .dropdown-footer {
		border-top: 1px solid rgba(220, 225, 239, 0.1);
		background: $dark-theme3;
	}
	.dropdown-menu {
		color: $white;
		background-color: $dark-theme;
		border: 1px solid rgba(138, 153, 191, 0.125);
	}
	.main-message-list a {
		.name {
			color: $white;
		}
		.time, .desc {
			color: $white-4;
		}
	}
	.task-line a {
		color: $white;
	}
	.latest-tasks .nav-tabs .nav-link {
		color: #7987a1;
		background: transparent;
	}
	.chips p {
		color: $white-5;
	}
	.chip {
		color: $white;
		background-color: rgba(245, 246, 251, 0.1);
	}
}
@media (min-width: 1245px) {
	.dark-theme .main-header-center .form-control {
		border-color: $white-1 !important;
		background-color: $white-1 !important;
	}
}
.chip:hover {
	background: #8485fb;
	color: $white;
}
.dark-theme {
	.latest-tasks .check-box .ckbox span:before {
		border: 1px solid rgba(217, 226, 255, 0.1);
	}
	.table {
		th, td {
			border-top: 1px solid rgba(227, 231, 237, 0.1);
			border-bottom-color: rgba(227, 231, 237, 0.1) !important;
		}
	}
	#global-loader {
		background: #1a233a;
	}
	.app-sidebar {		
		border-top: 0;
		box-shadow: none;
		border-right: 0px solid #353c65;
	}	
	.side-menu__label, .side-menu .side-menu__icon, .app-sidebar__user .user-info h4 {
		color: $white-7;
	}
	.main-sidebar-header {
		border-bottom: 0px solid rgba(222, 228, 236, 0.1);
		border-right: 0px solid rgba(222, 228, 236, 0.03);
	}
	.main-sidebar-loggedin .media-body h6 {
		color: $white;
	}
	.app-sidebar .slide.active .side-menu__item {
		background: rgba(255, 255, 255, 0.1);
	}
	.slide {
		&.is-expanded {
			a {
				color: $white-6;
			}
		}
	}
	.side-menu__item {
		color: $white-7;
		&.active {
			.side-arrow {
				background: $dark-theme2 !important;
			}
			.side-angle2 {
				border-color: transparent $dark-theme2 transparent transparent;
			}
			.side-angle1 {
				border-color: transparent $dark-theme2 transparent transparent;
			}
		}
	}
	.app-sidebar__user .user-pro-body img {
		border: 2px solid #8e8ef1;
		background: rgba(109, 110, 243, 0.2);
	}
	.slide.is-expanded:before {
		background: rgba(227, 231, 237, 0.1);
	}
	.slide-menu .slide-item:before {
		border-color: #6d7582;
	}
	.desktop-logo {}
	.open-toggle svg g, .close-toggle svg g {
		fill: $white;
	}
	.angle {
		color: $white-4 !important;
	}
	.main-header-center {
		.btn:hover, .sp-container button:hover {
			color: $white;
		}
	}
	.sp-container .main-header-center button:hover {
		color: $white;
	}
	.main-header-center {
		.btn:focus, .sp-container button:focus {
			color: $white;
		}
	}
	.sp-container .main-header-center button:focus, .main-header .input-group-btn .btn i {
		color: $white;
	}
	.main-mail-item {
		&.unread {
			background-color: $dark-theme;
		}
		border-top: 1px solid rgba(227, 231, 237, 0.1);
		border-bottom: 1px solid rgba(227, 231, 237, 0.1);
	}
	.main-content-title {
		color: $white;
	}
	.main-mail-options {
		border: 1px solid rgba(226, 232, 245, 0.1);
		border-bottom: 0;
	}
	.main-mail-list {
		border: 1px solid rgba(227, 231, 237, 0.1);
	}
	.main-mail-item {
		background-color: $dark-theme;
	}
	.main-mail-subject strong {
		color: $white-7;
	}
	.ckbox span:before {
		background-color: rgba(227, 231, 237, 0.1);
		border: 1px solid rgba(227, 231, 237, 0.1);
	}
	.main-mail-star {
		color: $white-1;
	}
	.main-nav-column .nav-link {
		color: #7987a1;
		&:hover, &:focus {
			color: $white;
		}
	}
	.btn-light {
		color: $white;
		background-color: rgba(226, 232, 245, 0.1);
		border-color: rgba(189, 198, 214, 0.2);
	}
	.main-nav-column .nav-link {
		&:hover i:not([class*=' tx-']), &:focus i:not([class*=' tx-']) {
			color: $white;
		}
		+.nav-link {
			border-top: 0px dotted rgba(180, 189, 206, 0.3);
		}
	}
	.nav-search .input-group-text {
		color: $white;
		background-color: $white-2;
		border: 1px solid$white-1;
		border-left: 0;
	}
	.main-nav-column .nav-link.active {
		color: #6d6ef3 !important;
		&:hover, &:focus {
			color: #6d6ef3 !important;
		}
	}
	.main-mail-header .btn-group .btn {
		border-color: #555c6e;
		background-color: #555c6e;
	}
}
/*----- Left-Sidemenu -----*/

/*----- Left-Sidemenu -----*/

.main-mail-header .btn-group .sp-container button, .sp-container .main-mail-header .btn-group button {
	border-color: #555c6e;
	background-color: #555c6e;
}
.dark-theme {
	.main-mail-header .btn-group {
		.btn.disabled, .sp-container button.disabled {
			background-color: #555c6e;
			color: #e2e8f5;
			border-color: #555c6e;
		}
	}
	.sp-container .main-mail-header .btn-group button.disabled {
		background-color: #555c6e;
		color: #e2e8f5;
		border-color: #555c6e;
	}
	.main-mail-header .btn-group {
		.btn:hover, .sp-container button:hover {
			color: $white;
			background-color: $white-3;
			border-left: 0;
		}
	}
	.sp-container .main-mail-header .btn-group button:hover {
		color: $white;
		background-color: $white-3;
		border-left: 0;
	}
	.main-mail-header .btn-group {
		.btn:focus, .sp-container button:focus {
			color: $white;
			background-color: $white-3;
			border-left: 0;
		}
	}
	.sp-container .main-mail-header .btn-group button:focus {
		color: $white;
		background-color: $white-3;
		border-left: 0;
	}
	.card-header, .card-footer {
		position: relative;
		border-color: rgba(226, 232, 245, 0.1);
	}
	hr {
		border-color: rgba(226, 232, 245, 0.1);
	}
	.main-content-label, .card-table-two .card-title, .card-dashboard-eight .card-title {
		color: $white;
	}
	.form-label {
		color: #cfdaec;
	}
	.select2-container--default .select2-selection--single {
		background-color: $dark-theme;
		border-color: rgba(226, 232, 245, 0.2);
		.select2-selection__rendered {
			color: #cfdaec;
		}
	}
	.select2-dropdown {
		background-color: $dark-theme;
		border-color: rgba(225, 230, 241, 0.2);
	}
	.select2-container--default {
		.select2-results__option[aria-selected="true"] {
			background-color: $white-05;
		}
		.select2-search--dropdown .select2-search__field {
			border-color: rgba(226, 232, 245, 0.2);
			background: $dark-theme;
			color: $white;
		}
	}
	.main-nav-line-chat {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1) !important;
	}
	.icons-list-item{
		background-color: $dark-theme;
		border-color: rgba(225, 230, 241, 0.1);
	}
	.icons-list{
		background-color: $dark-theme3;
		border-color: rgba(225, 230, 241, 0.1);
	}
	.main-nav-line .nav-link {
		color: $white-4;
	}
	.main-chat-msg-name h6 {
		color: $white;
	}
	.main-chat-header {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}	
	.main-chat-contacts-more {
		background-color: #6d6ef3;
	}
	.main-chat-list {
		.media {
			&.new {
				background-color: $dark-theme;
				.media-body p {
					color: #a9b2c7;
				}
				.media-contact-name span:first-child {
					color: #f3f6fb;
				}
			}
			border-bottom: 1px solid rgba(226, 232, 245, 0.1);
		}
		.media-contact-name span:first-child {
			color: #fff !important;
		}
		.media.selected {
			background-color: $dark-theme3;
		}
	}
	.main-chat-contacts-wrapper {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}
	.main-chat-list .media {
		&:hover, &:focus {
			background-color: $dark-theme3;
		}
		&.selected .media-body p {
			color: #b7bfd2;
		}
	}
	.main-msg-wrapper {
		background-color: rgba(226, 232, 245, 0.1);
		color: $white;
	}
	.main-chat-footer {
		border-top: 1px solid rgba(226, 232, 245, 0.1);
		background-color: $dark-theme;
		z-index: 999;
		.form-control {
			background: transparent;
			border-color: transparent;
		}
	}
	.irs-line-mid, .irs-line-left, .irs-line-right {
		background-color: $dark-theme2;
	}
	.irs-min, .irs-max {
		color: $white;
		background: $white-1;
	}
	.main-calendar .fc-header-toolbar h2 {
		color: $white;
	}
	.ui-datepicker {
		background-color: $dark-theme;
		box-shadow: none;
		border: 1px solid $white-1;
		.ui-datepicker-calendar td {
			border: 0px solid $white-1;
			background-color: $dark-theme;
			span, a {
				color: $white-4;
			}
		}
		.ui-datepicker-title, .ui-datepicker-calendar th {
			color: $white;
		}
	}
	.main-datepicker .ui-datepicker .ui-datepicker-calendar th {
		color: #fcfcfc;
	}
	.card--calendar .ui-datepicker .ui-datepicker-header {
		border-bottom: 1px solid rgba(227, 227, 227, 0.1);
	}
	.ui-datepicker .ui-datepicker-calendar td a:hover {
		background-color: transparent;
		color: $white;
	}
	.main-calendar {
		.fc-view>table {
			background-color: $dark-theme;
		}
		.fc-head-container .fc-day-header {
			color: $white;
		}
		.fc-view {
			.fc-day-number {
				color: $white;
			}
			.fc-other-month {
				background-color: rgba(40, 92, 247, 0.07);
			}
		}
		.fc-content {
			border-color: rgba(226, 232, 245, 0.1);
		}
	}
	.ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input{
		background:$dark-theme;
		color:$white;
	}
}
.main-calendar .fc-divider {
	border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme .main-calendar {
	.fc-list-heading td, .fc-list-view, .fc-popover, .fc-row, tbody, td {
		border-color: rgba(226, 232, 245, 0.1);
	}
}
.main-calendar th {
	border-color: rgba(226, 232, 245, 0.1);
}
.dark-theme {
	.custom-switch-indicator {
		background: $dark-theme;
		border: 1px solid rgba(226, 232, 245, 0.1);
	}
	.custom-switch-input:checked ~ .custom-switch-description {
		color: $white-5;
	}
	.main-calendar {
		thead {
			border-color: rgba(226, 232, 245, 0.1);
		}
		.fc-view .fc-day-number {
			&:hover, &:focus {
				color: $white;
				background-color: transparent;
			}
		}
		td.fc-today {
			background-color: #6d6ef3;
		}
		.fc-view>table> {
			thead {
				th, td {
					border-color: $white-1;
				}
			}
			tbody>tr>td {
				border-color: rgba(226, 232, 245, 0.1);
			}
		}
		.fc-header-toolbar button {
			background-color: #384361;
			border: 1px solid rgba(226, 232, 245, 0.1);
			&.fc-state-active {
				background-color: #6d6ef3;
			}
		}
		.fc-view {
			&.fc-listMonth-view .fc-list-item, &.fc-listWeek-view .fc-list-item {
				background-color: #333d5a;
			}
		}
	}
	.fc-unthemed {
		.fc-divider, .fc-list-heading td, .fc-popover .fc-header {
			background: #384361;
		}
	}
	.main-calendar .fc-view {
		&.fc-listMonth-view .fc-list-item-title .fc-desc, &.fc-listWeek-view .fc-list-item-title .fc-desc {
			color: $white-6;
		}
		&.fc-listMonth-view .fc-list-item-title a, &.fc-listWeek-view .fc-list-item-title a, &.fc-listMonth-view .fc-list-heading-main span:last-child, &.fc-listWeek-view .fc-list-heading-main span:last-child {
			color: $white;
		}
	}
	.main-contact-info-header .media-body p, .tx-inverse {
		color: $white-6;
	}
	.contact-icon:hover {
		background: $white-1;
		color: $white;
	}
	.main-contact-info-header {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}
	.main-contact-info-body {
		.media-body {
			span {
				color: $white-8;
			}
			label {
				color: $white-4;
			}
		}
		.media+.media::before {
			border-top: 0px dotted rgba(226, 232, 245, 0.2);
		}
	}
	.main-contact-body {
		span {
			color: $white-4;
		}
		h6 {
			color: $white-8;
		}
	}
	.main-contact-item {
		&.selected {
			border-left-color: #6d6ef3;
			border-top-color: rgba(226, 232, 245, 0.01) !important;
			border-bottom-color: rgba(226, 232, 245, 0.01) !important;
			background-color: $dark-theme3;
		}
		+.main-contact-item {
			border-top-color: rgba(227, 231, 237, 0.19);
			&::before {
				border-top: 1px solid rgba(227, 231, 237, 0.05);
			}
		}
		&:hover, &:focus {
			background-color: $dark-theme3;
			border-top-color: rgba(227, 231, 237, 0.1);
			border-bottom-color: rgba(227, 231, 237, 0.1);
		}
	}
	.main-contact-label::after {
		border-bottom: 1px solid rgba(227, 231, 237, 0.1);
	}
	#ui_notifIt.default {
		background-color: #3e465b;
		border: 1px solid rgba(227, 231, 237, 0.19);
	}
	.notifit_confirm, .notifit_prompt {
		background-color: #3e465b;
	}
	.alert .close {
		color: $white;
		opacity: .7;
	}
	.main-contact-info-header .main-img-user a{
		background-color:$dark-theme2;
		box-shadow: 0 0 0 2px $dark-theme2;    
	}
	.tree {
		li {
			a {
				text-decoration: none;
				color: $white-8;
			}
			color: $white-4;
		}
		ul {
			li:last-child:before {
				background: transparent;
			}
			&:before {
				border-left: 1px solid rgba(227, 227, 227, 0.2);
			}
			li:before {
				border-top: 1px solid rgba(227, 227, 227, 0.2);
			}
		}
	}
	.text-muted {
		color: $white-4 !important;
	}
	.main-icon-group {
		color: $white-8;
	}
	.main-icon-list {
		border: 1px solid rgba(225, 230, 241, 0.1);
	}
	.table thead th {
		border-bottom: 1px solid rgba(227, 231, 237, 0.1);
		border-top: 0 !important;
	}
	.table-hover tbody tr:hover {
		color: $white;
		background-color: $dark-theme3 !important;
	}
	table.dataTable {
		tbody td.sorting_1 {
			background-color: $dark-theme;
		}
		border: 1px solid rgba(226, 232, 245, 0.1);
		thead {
			th, td {
				color: $white;
			}
			.sorting_asc, .sorting_desc {
				background-color: $dark-theme3;
			}
		}
	}
	#example-delete.table thead th {
		border-bottom: 0;
	}
	.dataTables_wrapper {
		.dataTables_length, .dataTables_filter, .dataTables_info, .dataTables_processing, .dataTables_paginate {
			color: $white;
		}
		.dataTables_filter input {
			border: 1px solid rgba(226, 232, 245, 0.2);
		}
		.dataTables_paginate .paginate_button {
			background-color: rgba(227, 231, 237, 0.1);
		}
	}
	.page-link {
		color: $white;
		background-color: $dark-theme3;
	}
	.dataTables_wrapper .dataTables_paginate {
		.paginate_button.active {
			background: #6d6ef3 !important;
		}
		.page-item.disabled .page-link {
			background-color: transparent;
		}
	}
	select option {
		background: $dark-theme;
	}
	table.dataTable tbody tr.selected {
		background: rgba(242, 244, 247, 0.1);
	}
	.example {
		padding: 1rem;
		border: 1px solid rgba(225, 230, 241, 0.1);
	}
	#basic-alert .alert .close, #dismiss-alerts .alert .close {
		color: $black;
		opacity: .3;
	}
	#icon-dismissalerts {
		.alert .close {
			color: $black;
			opacity: .3;
		}
		.alert-default.alert-dismissible .close {
			color: $white;
		}
	}
	.main-table-reference> {
		thead>tr> {
			th, td {
				border: 1px solid rgba(226, 232, 245, 0.1);
			}
		}
		tbody>tr> {
			th, td {
				border: 1px solid rgba(226, 232, 245, 0.1);
			}
		}
	}
	pre {
		color: #dfe1ef;
		background-color: $dark-theme3;
		text-shadow: 0 1px #242266;
	}
	code.language-markup {
		background: transparent;
		border: transparent;
	}
	.token {
		color: #dc2a2a;
		&.selector, &.attr-name, &.string, &.char, &.builtin, &.inserted {
			color: #4e9cf1;
		}
		&.atrule, &.attr-value, &.keyword {
			color: #e40dae;
		}
		&.operator, &.entity, &.url {
			color: #ecc494;
			background: $white-1;
		}
	}
	.language-css .token.string, &.style .token.string {
		color: #ecc494;
		background: $white-1;
	}

	.highlight {
		border: 2px solid rgba(225, 230, 241, 0.1) !important;
		background: $dark-theme3;
	}
	.clipboard-icon {
		background: $dark-theme;
		border: 1px solid rgba(225, 230, 241, 0.1);
	}
	.main-table-reference {
		>thead>tr> {
			th, td {
				background: $dark-theme3 !important;
				color: $white-8;
				border: 1px solid rgba(225, 230, 241, 0.1) !important;
			}
		}
		background: transparent;
	}
	.breadcrumb-style1 .breadcrumb-item a, .breadcrumb-style2 .breadcrumb-item a, .breadcrumb-style3 .breadcrumb-item a {
		color: $white-4;
	}
	.breadcrumb {
			background-color: $dark-theme3;
		}
	.dropdown-item {
		color: $white-6;
		&:hover, &:focus {
			background: $dark-theme3;
		}
	}
	.dropdown-divider {
		border-top: 1px solid rgba(227, 231, 237, 0.16);
	}
	.img-thumbnail {
		background-color: $white-1;
		border: 1px solid rgba(226, 232, 245, 0.1);
		p {
			color: $white-5;
			font-size: 13px;
		}
	}
	.bd {
		border-color: rgba(226, 232, 245, 0.1);
	}
	.bg-light {
		background-color: $dark-theme2 !important;
	}
	.main-nav .nav-link {
		&:hover, &:focus {
			color: $white;
		}
	}
	.nav-pills .nav-link {
		color: $white-4;
		&:hover, &:focus {
			color: $white;
		}
		&.active {
			color: #fff !important;
		}
	}
	.main-nav .nav-link {
		color: $white-4;
		border-color:rgba(225, 230, 241, 0.1);
	}
	.bg-gray-300 {
		background-color: #323b54;
	}
	#tab .bg-gray-300 {
		background-color: $dark-theme2;
		border-bottom: 1px solid #404563;
	}
	.nav-tabs .nav-link {
		background-color: $dark-theme;
		color: $white;
		&.active, &:hover, &:focus {
			background-color: rgb(106, 108, 235);
			color: $white;
		}
	}
	.popover-static-demo {
		background-color: $dark-theme3;
	}
	.popover {
		background-color: $dark-theme;
		box-shadow: 0 5px 15px 5px rgb(24, 29, 62);
	}
	.popover-body {
		color: $white-4;
		border: 1px solid $white-1;
	}
	.popover-header {
		color: $white-7;
		background-color: $dark-theme;
		border-color: rgba(225, 230, 241, 0.1);
	}
	.bs-popover-top>.arrow::before, .bs-popover-auto[x-placement^="top"]>.arrow::before {
		border-top-color: rgba(225, 230, 241, 0.1);
	}
	.bs-popover-top>.arrow::after, .bs-popover-auto[x-placement^="top"]>.arrow::after {
		border-top-color: $dark-theme;
	}
	.bs-popover-bottom>.arrow::after, .bs-popover-auto[x-placement^="bottom"]>.arrow::after {
		border-bottom-color: $dark-theme;
	}
	.bs-popover-bottom>.arrow::before, .bs-popover-auto[x-placement^="bottom"]>.arrow::before {
		border-bottom-color: rgba(225, 230, 241, 0.1);
	}
	.bs-popover-left>.arrow::after, .bs-popover-auto[x-placement^="left"]>.arrow::after, .bs-popover-left>.arrow::after, .bs-popover-auto[x-placement^="left"]>.arrow::after {
		border-left-color: $dark-theme;
	}
	.bs-popover-right>.arrow::after, .bs-popover-auto[x-placement^="right"]>.arrow::after {
		border-right-color: $dark-theme;
	}
	.bs-popover-right>.arrow::before, .bs-popover-auto[x-placement^="right"]>.arrow::before {
		border-right-color: rgba(225, 230, 241, 0.1);
	}
	.bs-popover-left>.arrow::before, .bs-popover-auto[x-placement^="left"]>.arrow::before {
		border-left-color: rgba(225, 230, 241, 0.1);
	}
	.bg-gray-200 {
		background-color: $dark-theme3;
	}
	.media-body {
		font-size: 13px;
		color: $white-7;
	}
	.bg-gray-100 {
		background-color: $dark-theme3;
	}
	.tooltip-static-demo {
		background-color: $dark-theme3;
	}
	.toast-header {
		border-bottom-color: rgba(205, 212, 224, 0.2);
	}
	.toast {
		background-color: $dark-theme;
		border-color: rgba(225, 230, 241, 0.1);
	}
	.toast-header {
		color: rgba(255, 255, 255, 0.58);
		background: $dark-theme3;
	}
	.bootstrap-tagsinput {
		.badge {
		}
		background-color: $dark-theme;
	}
	.tag {
		color: $white;
		background-color: rgba(239, 239, 245, 0.1);
	}
	.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single{
		border-color: rgba(239, 239, 245, 0.1);
	}
	
	.accordion {
		.card-header a {
			color: $white;
			background-color: $dark-theme3;
		}
		.card-body {
			background-color:$dark-theme3;
		}
		.card-header a.collapsed {
			&:hover, &:focus {
				color: $white;
				background-color: #6d6ef3;
			}
		}
	}
	.modal-content {
		background-color: $dark-theme;
		border: 1px solid rgba(255, 255, 255, 0.18);
	}
	.modal-header {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}
	.modal-title {
		color: $white;
	}
	.modal-footer {
		border-top: 1px solid rgba(226, 232, 245, 0.1);
	}
	.close {
		color: #f7f6f6;
		text-shadow: 0 1px 0 #18213c;
	}
	.modal-content-demo .modal-body h6 {
		color: $white;
	}
	.vtimeline {
		.timeline-wrapper {
			.timeline-panel {
				background: $dark-theme3;
				box-shadow: 0 5px 15px 5px $dark-theme2;
			}
			&.timeline-inverted .timeline-panel:after {
				border-right: 14px solid $dark-theme3;
				border-left: 0 solid $dark-theme3;
			}
			.timeline-panel:after {
				border-left: 14px solid $dark-theme3;
				border-right: 0 solid $dark-theme3;
			}
		}
		&:before {
			background-color: $dark-theme3;
		}
	}
	.timeline-body {
		color: $white-6;
	}
	.sweet-alert {
		background-color: $dark-theme;
		h2 {
			color: $white;
		}
	}
	.btn-outline-light {
		border-color: rgba(151, 163, 185, 0.2);
		color: #97a3b9;
		&:hover, &:focus {
			background-color: #3d4765 !important;
			border: 1px solid #455177 !important;
			box-shadow: none !important;
			color: #fff !important;
		}
	}
	.dropdown .fe-more-vertical {
		color: $white-6;
	}
	.main-content-body-profile .nav {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}
	.card-body+.card-body {
		border-top: 1px solid rgba(226, 232, 245, 0.1);
	}
	.rating-stars {
		input {
			color: $white;
			background-color: $dark-theme3;
			border: 1px solid rgba(234, 237, 241, 0.1);
		}
		.rating-stars-container .rating-star {
			color: $dark-theme3;
			&.is--active, &.is--hover {
				color: #f1c40f !important;
			}
		}
	}
	.br-theme-bars-horizontal .br-widget a {
		&.br-active, &.br-selected {
			background-color: #6d6ef3 !important;
		}
	}
	.br-theme-bars-pill .br-widget a {
		&.br-active, &.br-selected {
			background-color: #6d6ef3 !important;
			color: white;
		}
	}
	.br-theme-bars-1to10 .br-widget a, .br-theme-bars-movie .br-widget a, .br-theme-bars-horizontal .br-widget a {
		background-color: $dark-theme3;
	}
	.br-theme-bars-1to10 .br-widget a {
		&.br-active, &.br-selected {
			background-color: #6d6ef3 !important;
		}
	}
	.br-theme-bars-movie .br-widget a {
		&.br-active, &.br-selected {
			background-color: #6d6ef3 !important;
		}
	}
	.br-theme-bars-square .br-widget a {
		border: 2px solid #4f576f;
		background-color: $dark-theme3;
		color: #8694a5;
		&.br-active, &.br-selected {
			border: 2px solid #6d6ef3;
			color: #6d6ef3;
		}
	}
	.br-theme-bars-pill .br-widget a {
		background-color: $dark-theme3;
	}
	.custom-file-label {
		color: $white-7;
		background-color: rgba(255, 255, 255, 0.07);
		border: 1px solid rgba(255, 255, 255, 0.07);
		&::after {
			color: $white;
			background-color: #6d6ef3;
			border: 1px solid #6d6ef3;
		}
	}
	.input-group-text {
		color: $white-5;
		border: 1px solid rgba(255, 255, 255, 0.12);
	}
	.select2-container--default .select2-selection--single .select2-selection__placeholder {
		color: $white-4;
	}
	.sp-replacer {
		border-color: rgba(255, 255, 255, 0.12);
		background-color: #2d3653;
		&:hover, &:focus {
			border-color: $white-2;
		}
	}
	.sp-container {
		background-color: $dark-theme;
		border-color: rgba(226, 232, 245, 0.2);
	}
	.select2-container--default {
		&.select2-container--focus .select2-selection--multiple {
			border-color: $white-1;
			background: $dark-theme;
		}
		.select2-selection--multiple {
			background-color: $dark-theme !important;
			border-color: $white-1;
		}
		&.select2-container--disabled .select2-selection--multiple {
			background-color: $white-1 !important;
		}
	}
	.select2-container--default.select2-container--disabled .select2-selection--single{
		background-color: $white-1 !important;
	}
	.SumoSelect {
		> {
			.CaptionCont {
				border: 1px solid rgba(225, 230, 241, 0.1);
				color: #99a6b7;
				background-color: $dark-theme;
			}
			.optWrapper {
				background: $dark-theme;
				border: 1px solid rgba(234, 234, 236, 0.15);
				box-shadow: 0 2px 17px 2px rgb(7, 4, 86);
			}
		}
		.select-all {
			border-bottom: 1px solid rgba(234, 234, 236, 0.15);
			background-color: $dark-theme;
		}
		>.optWrapper> {
			.options li.opt {
				border-bottom: 1px solid rgba(234, 234, 236, 0.15);
			}
			.MultiControls {
				border-top: 1px solid rgba(234, 234, 236, 0.15);
				background-color: $dark-theme;
			}
		}
		&.open>.optWrapper {
			box-shadow: 0 2px 17px 2px rgb(28, 33, 64);
		}
		>.optWrapper {
			>.options li.opt:hover {
				background-color: $dark-theme3;
			}
			&.multiple>.options li.opt span i {
				border: 1px solid $white-1;
				background-color: $white-1;
			}
		}
		.select-all>span i {
			border: 1px solid$white-1;
			background-color: $white-1;
		}
	}
	.dropify-wrapper {
		background-color: $white-1;
		border: 1px solid rgba(239, 242, 247, 0.07);
		color: $white;
		.dropify-preview {
			background-color: $white-1;
		}
		&:hover {
			background-image: -webkit-linear-gradient(135deg, rgba(250, 251, 254, 0.05) 25%, transparent 25%, transparent 50%, rgba(250, 251, 254, 0.1) 50%, rgba(250, 251, 254, 0.1) 75%, transparent 75%, transparent);
			background-image: linear-gradient(-45deg, rgba(250, 251, 254, 0.1) 25%, transparent 25%, transparent 50%, rgba(250, 251, 254, 0.1) 50%, rgba(250, 251, 254, 0.1) 75%, transparent 75%, transparent);
			-webkit-animation: stripes 2s linear infinite;
			animation: stripes 2s linear infinite;
		}
	}
	.ff_fileupload_wrap .ff_fileupload_dropzone {
		border: 2px dashed #5e6882;
		background-color: $dark-theme3;
		background-image: url(../plugins/fancyuploder/fancy_upload-dark.png);
		&:hover, &:focus, &:active {
			background-color: $white-1;
			border-color: $white-1;
		}
	}
	.main-form-group {
		border: 1px solid rgba(226, 232, 245, 0.1);
		.form-control {
			padding: 0 15px;
		}
	}
	.parsley-style-1 {
		.parsley-input.parsley-error .form-control, .parsley-checkbox.parsley-error, .parsley-select.parsley-error .select2-container--default .select2-selection--single {
			background-color: $dark-theme3;
		}
	}
	.wizard {
		border: 1px solid rgba(227, 231, 237, 0.1);
		background-color: $dark-theme3;
		> {
			.steps a {
				.number, &:hover .number, &:active .number {
					background-color: #30355d;
				}
			}
			.content {
				>.title {
					color: $white;
				}
				border-top: 1px solid rgba(227, 231, 237, 0.1);
				border-bottom: 1px solid rgba(227, 231, 237, 0.1);
			}
		}
	}
	#wizard3.wizard.vertical>.content {
		border-top: 0;
		border-bottom: 0;
	}
	.ql-scrolling-demo {
		border: 2px solid$white-1;
		.ql-container .ql-editor {
			color: $white-8;
		}
	}
	.ql-snow {
		.ql-picker-label {
			border: 1px solid rgba(255, 255, 255, 0.12);
			background-color: $dark-theme3;
		}
		.ql-stroke {
			stroke: $white-8;
		}
		.ql-editor, &.ql-toolbar button {
			color: $white-8;
		}
		.ql-picker {
			color: $white-5;
		}
		&.ql-toolbar {
			border: 2px solid$white-1;
		}
		&.ql-container {
			border-color: $white-1;
		}
		.ql-picker-options {
			background-color: $dark-theme;
		}
	}
	.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
		border-color: #545b6d;
	}
	#modalQuill .modal-header {
		border-bottom: 0;
	}
	.main-profile-work-list .media-body {
		h6 {
			color: $white;
		}
		p {
			color: $white-4;
		}
	}
	.main-profile-contact-list .media-body {
		div {
			color: $white-6;
		}
		span {
			color: $white;
		}
	}
	.plan-icon {
		border: 1px solid rgba(245, 246, 251, 0.1);
		background: rgba(245, 246, 251, 0.1);
	}
	.bg-success-transparent {
		background-color: rgba(77, 236, 146, 0.17) !important;
	}	
	.bg-warning-transparent {
		background-color: rgba(245, 222, 143, 0.15) !important;
	}
	.bg-pink-transparent {
		background-color: rgba(249, 123, 184, 0.1) !important;
	}
	.bg-teal-transparent {
		background-color: rgba(166, 243, 243, 0.12) !important;
	}
	.bg-purple-transparent {
		background-color: rgba(163, 154, 249, 0.1) !important;
	}
	.bg-danger-transparent {
		background-color: rgba(243, 161, 161, 0.1) !important;
	}
	.main-profile-name, .main-profile-body .media-body h6 {
		color: #fbfcff;
	}
	.main-profile-social-list .media-body a {
		color: $white;
		opacity: 0.5;
	}
	.profile-footer a {
		background: $dark-theme3;
		color: $white;
	}
	.billed-from h6 {
		color: #f4f5f8;
	}
	.invoice-title {
		color: rgb(225, 225, 225);
	}
	.main-invoice-list {
		.media-body h6 {
			color: $white;
		}
		.selected {
			background-color: $dark-theme3;
			border-top-color: rgba(226, 232, 245, 0.1);
			border-bottom-color: rgba(226, 232, 245, 0.1);
			border-left-color: #6d6ef3;
		}
		.media {
			+.media::before {
				border-top: 0px dotted transparent;
			}
			border: 0px dotted rgba(226, 232, 245, 0.1);
			&:hover, &:focus {
				background-color: $dark-theme3;
			}
		}
		.media-body p {
			color: #9da5b5;
			span {
				color: #cbcfd8;
			}
		}
	}
	.table-invoice tbody>tr>th:first-child .invoice-notes p {
		color: $white-6;
	}
	.to-do-tasks .list-group-item {
		border: 0;
	}
}
@media (max-width: 767px) {
	.dark-theme .vtimeline .timeline-wrapper .timeline-panel:after {
		border-right: 14px solid $dark-theme3 !important;
		border-left: 0 solid $dark-theme3 !important;
	}
}
@media (min-width: 576px) {
	.dark-theme .wizard.vertical> {
		.content, .actions {
			border-left: 1px solid rgba(226, 232, 245, 0.1);
		}
	}
}
.table-invoice tbody>tr>td:first-child .invoice-notes p {
	color: $white-6;
}
.dark-theme {
	.table-invoice tbody>tr> {
		th:first-child, td:first-child {
			color: $white-6;
		}
	}
	.billed-from p, .billed-to p {
		color: $white-5;
	}
	.card-invoice .tx-gray-600 {
		color: #eef0f3;
	}
	.billed-to h6 {
		color: #d3d8e2;
	}
	.invoice-info-row {
		+.invoice-info-row {
			border-top: 1px dotted rgba(226, 232, 245, 0.15);
		}
		span:first-child {
			color: $white-3;
		}
	}
	.main-invoice-list {
		border-top: 1px solid rgba(226, 232, 245, 0.1);
	}
	.card-category {
		background: rgba(239, 242, 246, 0.1);
	}
	.pricing-card .list-unstyled li {
		border-bottom: 1px solid rgba(234, 237, 241, 0.1);
	}
	.price {
		&.panel-color>.panel-body, .panel-footer {
			background-color: $white-1;
		}
	}
	.pricing .list-unstyled li {
		border-bottom: 1px solid rgba(234, 237, 241, 0.1);
	}
	.card--events .list-group-item h6 {
		color: $white-6;
	}
	.rdiobox span:before {
		background-color: $dark-theme3;
		border: 1px solid #4a5677;
	}
	.colorinput-color {
		border: 1px solid rgba(234, 240, 247, 0.2);
	}
	.nice-select {
		.list {
			background-color: $dark-theme;
			-webkit-box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
			box-shadow: 0px 0px 15px 1px rgb(4, 17, 56);
		}
		.option {
			&:hover, &.focus, &.selected.focus {
				background-color: rgba(237, 239, 245, 0.1);
			}
		}
	}
	.item-card {
		.cardtitle a {
			color: #fefefe;
		}
		.cardprice span {
			color: #dfe5ec;
		}
	}
	.bd-b {
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}
	.bd-r {
		border-right: 1px solid rgba(226, 232, 245, 0.1);
	}
	.bd-t {
		border-top: 1px solid rgba(226, 232, 245, 0.1);
	}
	.bd-l {
		border-left: 1px solid rgba(226, 232, 245, 0.1);
	}
	.bd-y {
		border-top: 1px solid rgba(226, 232, 245, 0.1);
		border-bottom: 1px solid rgba(226, 232, 245, 0.1);
	}
	.bd-x {
		border-left: 1px solid rgba(226, 232, 245, 0.1);
		border-right: 1px solid rgba(226, 232, 245, 0.1);
	}
	.bg-gray-500 {
		background-color: rgba(151, 163, 185, 0.2);
	}
	.bg-gray-400 {
		background-color: #5e687d;
	}
	.main-card-signin {
		background-color: $dark-theme;
		border: 1px solid rgba(227, 227, 227, 0.1);
	}
	.main-signin-header h4 {
		color: $white;
	}
	.main-signin-footer a {
		color: $white-8;
	}
	.main-signup-footer a {
		color: $white;
	}
	.main-error-wrapper {
		h1 {
			color: $white-8;
		}
		h2 {
			color: $white-5;
		}
		h6 {
			color: $white-3;
		}
	}
	.construction .btn.btn-icon {
		border: 1px solid rgba(221, 230, 241, 0.1);
	}
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
	width: 2px;
}
.dark-theme {
	.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
		background-color: #3b4563 !important;
	}
}
@media (min-width: 768px) {
	.dark-theme.sidebar-mini.sidenav-toggled {
		.main-sidebar-header {
			.icon-light .logo-icon {
				display: none;
				height: 2.5rem;
			}
			.icon-dark .logo-icon.dark-theme {
				display: block;
				height: 2.5rem;
			}
		}
		&.sidenav-toggled1 .main-sidebar-header .logo-light .main-logo {
			display: none;
		}
	}
}
@media (max-width: 991px) and (min-width: 568px) {
	.dark-theme .horizontalMenucontainer .desktop-dark {
		margin-left: 1.4rem;
	}
}
.desktop-dark {
	height: 2rem;
}
@media (max-width: 991px) {
	.dark-theme {
		.animated-arrow span {
			background: $white;
			&:before, &:after {
				background: $white;
			}
		}
		&.active .animated-arrow span {
			background-color: transparent;
		}
	}
}
.dark-theme {
	.sidebar {
		background: $dark-theme;
	}
	.main-calendar.fc-list-empty {
		background-color: $dark-theme;
		border: 1px solid $dark-theme3;
	}
	.custom-control-label::before{
		background-color:$dark-theme3;
		border: 1px solid rgba(226, 232, 245, 0.2);
	}
	.custom-control-input:disabled ~ .custom-control-label::before{
		background-color:$white-1;
	}
}
@media (max-width: 575px) {
	.dark-theme .main-calendar .fc-header-toolbar button {
		&.fc-month-button::before, &.fc-agendaWeek-button::before, &.fc-agendaDay-button::before, &.fc-listWeek-button::before, &.fc-listMonth-button::before {
			color: $white;
		}
	}
}
.dark-them {
	.breadcrumb {
		background-color: rgba(226, 232, 245, 0.1);
	}
	table.dataTable {
		>tbody>tr.child ul.dtr-details>li {
			border-bottom: 1px solid rgba(239, 239, 239, 0.1);
		}
		&.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before {
			background-color: #6d6ef3;
		}
	}
}
/*----- Horizontal-menu -----*/

.dark-theme {
	.horizontal-main.hor-menu {
		border-bottom: 0px solid rgba(213, 216, 226, 0.1);
		border-top: 1px solid rgba(213, 216, 226, 0.1);
	}
	.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active {
		color: $white;
		background: transparent;
	}
	.horizontalMenu>.horizontalMenu-list>li>a {
		color: $white;
	}
	.horizontalMenucontainer .main-header {
		box-shadow: none;
		border-bottom: 1px solid $white-1;
	}
	.hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
		background: rgba(238, 238, 247, 0.06);
		border-bottom: 0px solid rgba(231, 234, 243, 0.1);
	}
	.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		border: 1px solid rgba(231, 234, 243, 0.1);
		>li>a {
			color: $white-5;
		}
	}
	.sub-menu li a:before {
		border-color: $white-5;
	}
	.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		.sub-menu-sub:after {
			color: $white-8;
		}
		>li>ul.sub-menu {
			background-color: $dark-theme;
			box-shadow: 0 8px 16px 0 rgba(8, 13, 25, 0.4);
			border: none;
			>li>a {
				color: $white-5;				
			}
		}
	}
	.mega-menubg {
		background: $dark-theme;
		box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
		border: 1px solid rgba(231, 234, 243, 0.1);		
	}
	.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li a {
		background: transparent;
		color: $white-6;
	}
	.mega-menubg.hor-mega-menu h3 {
		color: $white;
	}
	.main-profile-menu .dropdown-item+.dropdown-item {
		border-top: 1px solid rgba(226, 234, 249, 0.1);
	}
	.main-profile-menu {
		.dropdown-item {		
			&:hover, &:focus {
				background-color: $dark-theme3;
			}
		}
	}
	.rating-scroll h6 {
		color: $white;
	}
	.latest-tasks .nav-link {
		&.active, &:hover, &:focus {
			background: transparent;
			color: #6d6ef3;
		}
	}
	.main-calendar .fc-list-empty {
		background-color: $dark-theme;
		border: 1px solid #384361;
	}
	.card.bg-info-transparent {
		background: rgba(23, 162, 184, 0.2) !important;
	}
	.form-control::placeholder {
		color: rgba(212, 218, 236, 0.3) !important;
		opacity: 1;
	}
	.header-icon, .main-header-message .nav-link i, .main-header-notification .nav-link i, .nav-item.full-screen .nav-link i, .sales-flot .flot-chart .flot-x-axis>div span:last-child, .p-text .p-name {
		color: $white;
	}	
	.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu>li>a:hover {
		color: #696af1;
	}
	.sidebar {
		.tabs-menu ul li {
			a {
				border: 1px solid rgba(220, 231, 245, 0.1);
				background: #373d5c;
				color: $white;
			}			
		}
	}
	.datepicker> {
		.datepicker_inner_container> {
			.datepicker_calendar, .datepicker_timelist {
				background-color: $dark-theme;
			}
		}
		.datepicker_header {
			background-color: $dark-theme3;
			color: #ffffff;
		}
	}
	.main-datetimepicker {
		>.datepicker_inner_container> {
			.datepicker_calendar th {
				color: #fcfdff;
			}
			.datepicker_timelist {
				border-left: 1px solid$white-1;
				>div.timelist_item {
					&:hover, &:focus {
						background-color: #323850;
					}
				}
			}
		}
		border: 1px solid$white-1;
	}
	.datepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item.hover {
		color: $white;
		background-color: $dark-theme3;
	}
	.datetimepicker {
		.datetimepicker-days table thead tr:last-child th {
			color: $white;
		}
		table {
			th.dow {
				background: $dark-theme;
			}
			td.old {
				color: #7a82af;
			}
		}
	}
}
@media (max-width: 991px) {
	.dark-theme {
		.horizontalMenu>.horizontalMenu-list {
			>li>a {
				border-bottom-color: rgba(231, 234, 243, 0.1);
			}
		}
		.hor-menu .horizontalMenu>.horizontalMenu-list>li {
			>a {
				border-radius: 0;
			}
			&:hover .sub-icon {
				color: $white;
				background: transparent;
				border-bottom: 1px solid rgba(231, 234, 243, 0.1);
			}
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
			li:hover>a {
				background-color: rgba(253, 254, 255, 0.1);
				color: #eef0f7 !important;
			}
			>li> {
				a:hover:before {
					border-color: #eef0f7;
				}
				ul.sub-menu>li>a:hover {
					color: #fff !important;
					background-color: rgba(231, 231, 231, 0.1);
				}
			}
		}
		.hor-menu .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list li:hover a {
			&:before {
				border-color: #eef0f7 !important;
			}
			color: #eef0f7 !important;
		}
		.mega-menubg li a:before {
			border-color: #8594ad;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu .sub-menu-sub:after {
			display: none;
		}		
		.dark-logo-1 {
			display: block;
		}
	}
}
.dark-theme {
	.datetimepicker table td.new {
		color: #7a82af;
	}
	.dark-theme .datetimepicker table {
		td {
			background: $dark-theme;
			color: $white;
		}
		th.prev {
			background-color: $dark-theme;
			color: $white;
		}
	}
	.datetimepicker table th {
		&.next, &.switch {
			background-color: $dark-theme;
			color: $white;
		}
	}
}
.dark-theme .datetimepicker table th.prev:hover, .datetimepicker table th.prev:focus {
	background-color: $dark-theme;
}
.dark-theme .datetimepicker table th {
	&.next {
		&:hover, &:focus {
			background-color: $dark-theme;
		}
	}
	&.prev span::before, &.next span::before {
		color: $white;
	}
	&.switch:hover {
		background-color: $dark-theme;
		color: #5965f9;
	}
}
.datetimepicker table th.switch:focus {
	background-color: $dark-theme;
	color: #5965f9;
}
.dark-theme {	
	.iti__selected-flag {
		border-right: 1px solid rgba(226, 232, 245, 0.2);
	}
	.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
		background-color: $dark-theme3;
	}
	.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
		background: $dark-theme;
	}
	.dashboard-carousel .btn-icons {
		background: rgba(239, 242, 246, 0.2) !important;
		color: $white;
	}
	.slide.dashboard-carousel:hover {
		background: transparent;
	}
	.btn-light {
		&:focus, &.focus {
			box-shadow: 0 0 0 0.2rem rgba(165, 175, 191, 0.5);
		}
		&:not(:disabled):not(.disabled) {
			&:active, &.active {
				color: $white;
				background-color: rgba(226, 232, 245, 0.1);
				border-color: rgba(189, 198, 214, 0.2);
			}
		}
	}
	.show>.btn-light.dropdown-toggle {
		color: $white;
		background-color: rgba(226, 232, 245, 0.1);
		border-color: rgba(189, 198, 214, 0.2);
	}
	.modal-dialog {
		box-shadow: none;
	}
	.email-media .media-body {
		small {
			color: rgb(255, 255, 255);
		}
		.media-title {
			color: rgb(255, 255, 255);
			font-size: 15px;
		}
	}
	.page-item.disabled .page-link {
		color: $white;
		background: $dark-theme3;
	}
	.demo-gallery .pagination {
		border: 0px solid rgba(227, 231, 237, 0.2);
	}
	.chat {
		.msg_cotainer, .msg_cotainer_send {
			background-color: $dark-theme3;
		}
	}
	#basic .breadcrumb {
		background-color: $dark-theme3;
	}
	.latest-tasks .tasks .span {
		color: #ffffff;
	}
	.list-group-item-action {
		color: #7987a1;
	}
	.list-group-item.active {
		color: $white;
	}
	.list-group-item-success {
		color: #1f5c01;
		background-color: #c8e9b8;
	}
	.list-group-item-info {
		color: #0c5460;
		background-color: #bee5eb;
	}
	.list-group-item-warning {
		color: #856404;
		background-color: #ffeeba;
	}
	.list-group-item-danger {
		color: #721c24;
		background-color: #f5c6cb;
	}
	.bg-danger {
		background-color: $danger !important;
	}	
	.bg-secondary {
		background-color: #7987a1 !important;
	}
	.bg-gray-800 {
		background-color: $dark !important;
	}
	.bg-success {
		background-color: $success !important;
	}
	.bg-info {
		background-color: #17a2b8 !important;
		p.card-text {
			color: $white;
		}
	}
	.main-content-body.main-content-body-contacts .media-body h5 {
		color: $white;
	}
	.table-striped tbody tr:nth-of-type(2n+1) {
		background-color: $dark-theme3;
	}
	.dataTables_wrapper .dataTables_filter input {
		color: $white;
		&::placeholder {
			color: #97a3b9;
		}
	}
	.dataTables_paginate .page-link {
		background: transparent;
	}
	.iti__divider {
		border-bottom: 1px solid rgba(189, 198, 214, 0.2);
	}
	.iti__country.iti__highlight {
		background-color: $dark-theme3;
	}
	.iti__country-list {
		background-color: $dark-theme;
		box-shadow: 0 8px 16px 0 rgba(28, 39, 60, 0.1);
	}
	.iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
		background-color: $dark-theme3;
	}
	.price.panel-color>.panel-body {
		background-color: rgb(43, 48, 84);
		border: 1px solid rgba(234, 237, 241, 0.1);
		border-bottom: 0;
		border-top: 0;
	}
	.image-zoom-container{
		border: 1px solid rgba(234, 237, 241, 0.1);
	}
	.small-img{
		border-top: 1px solid rgba(234, 237, 241, 0.1);
	}
	.table-borderless th, .table-borderless td, .table-borderless thead th, .table-borderless tbody + tbody{
		border:0 !important;
	}
	table.dataTable tbody td.sorting_1 {
		background: $dark-theme3;
	}
	.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
		background-color: #3f4562;
		color: $white;
		&:hover, &:focus {
			background-color: #3f4562;
			color: $white;
		}
	}
	.main-nav-line .nav-link.active {
		color: $white;
	}
	.datetimepicker table {
		background: transparent;
	}
	.datetimepicker table td{
		background: transparent;
		color:$white-8;
	}
	.datetimepicker table th.prev, .datetimepicker table th.next, .datetimepicker table th.switch{
		background-color: transparent;
	}
}
@media only screen and (min-width: 992px) {
	.dark-theme {
		.hor-menu .horizontalMenu>.horizontalMenu-list>li>a:hover {
			color: $white;
			background: transparent;
		}
		.horizontalMenu>.horizontalMenu-list>li>ul.sub-menu {
			background: $dark-theme;
			box-shadow: 0 8px 16px 0 rgba(9, 17, 33, 0.4);
		}
	}
	.dark-theme {
		.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active .side-arrow {
			background: $dark-theme2;
		}
		.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active .side-angle2 {
			border-color: transparent $dark-theme2 transparent transparent;
		}
		.hor-menu .horizontalMenu>.horizontalMenu-list>li>a.active .side-angle1 {
			border-color: transparent $dark-theme2 transparent transparent;
		}
	}
}
@media (min-width: 768px) {
	.dark-theme .main-content-left-contacts {
		border-right: 1px solid rgba(226, 232, 245, 0.1);
	}
}
@media (min-width: 576px) {
	.dark-theme .main-calendar .fc-header-toolbar button {
		color: $white;
	}
}
@media (max-width: 575px) {
	.dark-theme .main-calendar .fc-header-toolbar .fc-left button {
		color: $white;
		&.fc-today-button {
			color: $black;
		}
	}
}
@media (max-width: 605px) {
	.dark-theme .fc-view, .fc-view>table {
		border: 1px solid rgba(226, 232, 245, 0.2);
	}
}
.dark-theme {
	.chat {
		abbr.timestamp, .msg_time, .msg_time_send {
			color: $white;
		}
	}
	.nav-tabs {
		border-bottom: 1px solid rgba(226, 232, 245, 0.2);
	}
	.nav-tabs .nav-link.active {
		border-color: rgba(226, 232, 245, 0.2)
	}
}
.dark-theme {
	.page-link {
		border: 1px solid rgba(226, 232, 245, 0.2);
	}	
	.option-dots2 {
		&:hover {
			background: $dark-theme3;
		}
	}
}
.dark-theme .gray-set {
	.bg-transparent {
		background-color: transparent !important;
	}
	.bg-gray-100 {
		background-color: $gray-100 !important;
	}
	.bg-gray-200 {
		background-color: $gray-200 !important;
	}
	.bg-gray-300 {
		background-color: $gray-300 !important;
	}
	.bg-gray-400 {
		background-color: $gray-400 !important;
	}
	.bg-gray-500 {
		background-color: $gray-500 !important;
	}
	.bg-gray-600 {
		background-color: $gray-600 !important;
	}
	.bg-gray-700 {
		background-color: $gray-700 !important;
	}
	.bg-gray-800 {
		background-color: $gray-800 !important;
	}
	.bg-gray-900 {
		background-color: $gray-900 !important;
	}
}
.dark-theme .main-card-signin {
	box-shadow: 0 1px 15px 1px rgb(25, 31, 58);
}
.dark-theme .page-item.disabled .page-link {
	border-color: $white-1;
	opacity: 0.5;
}
.dark-theme .ps>.ps__rail-y {
	background-color: $dark-theme;
}
.dark-theme .app-sidebar .ps>.ps__rail-y {
	background-color: transparent;
}
.dark-theme .slide.is-expanded .side-menu__icon, .dark-theme .slide.is-expanded .angle {
	color: $white !important;
}
.dark-theme .close-toggle {
	color: $white !important;
}
.dark-theme .slide-item.active, .dark-theme .slide-item:hover, .dark-theme .slide-item:focus {
	color: $white !important;
}
.dark-theme .side-menu .slide.active .side-menu__label, .dark-theme .side-menu .slide.active .side-menu__icon {
	color: $white;
}
.dark-theme .slide:hover .side-menu__label, .dark-theme .slide:hover .angle, .dark-theme .slide:hover .side-menu__icon {
	color: $white !important;
}
.dark-theme {
	.breadcrumb-item.active {
		color: $white-7;
		opacity: 0.7;
	}
	.error-wrapper {
		background: transparent !important;
		&:before {
			display: none;
		}
	}
	.accordion-primary {
		.card-header a {
			&.collapsed {
				font-weight: 500;
				letter-spacing: -.1px;
			}
		}
	}
	.accordion-dark {
		.card {
			border-color: $gray-900;
		}
		.card-header a {
			color: $white;
			&:hover, &:focus {
				color: $white;
			}
			&.collapsed {
				background-color: $gray-900;
				color: $white;
				font-weight: 500;
				letter-spacing: -.1px;
				&:hover, &:focus {
					background-color: $gray-900;
					color: $white;
				}
			}
		}
	}
	.accordion-gray {
		.card {
			border-color: $gray-600;
		}
		.card-header a {
			color: $white;
			&:hover, &:focus {
				color: $white;
			}
			&.collapsed {
				background-color: $gray-600;
				color: $white;
				font-weight: 500;
				letter-spacing: -.1px;
				&:hover, &:focus {
					background-color: $gray-600;
					color: $white;
				}
			}
		}
	}
	.main-header-search .form-control {
		border: 1px solid #353c65 !important;
	}
	.horizontalMenucontainer .main-header-search .form-control {
		border: 0px solid #353c65 !important;
		background:$white;
		color:$default-color;
		&::placeholder{
			color:$default-color !important;
			opacity:0.3;
		}
	}
	.html-code-icon {
		background: $dark-theme3;
		border:2px solid rgba(225, 230, 241, 0.1);
		border-bottom: 0;
	}
	::-webkit-scrollbar-thumb {
		background: #2f3556;
	}
	*:hover::-webkit-scrollbar-thumb {
		background: #2f3556;
	}
	.text-default {
		color: #e1e5ec;
	}
	.bd-xl-r {
		border-color: $white-1;
	}
	.main-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
		border-color: $white-1;
		background-color: #2b345e;
	}
	.main-calendar .fc-event {
		background-color: $white-1 !important;
		color: $white !important;
	}
	.main-content-left-chat {
		border-color: $white-1;
	}
	.ql-snow .ql-picker:hover .ql-picker-label, .ql-snow .ql-picker:focus .ql-picker-label {
		color: $white;
	}
	.main-toggle span {
		background-color: $dark-theme;
	}
	.card--calendar .ui-datepicker-inline {
		border-width: 0 !important;
	}
	.wizard>.actions .disabled a {
		background-color: #444a67;
	}
	.main-msg-wrapper:before {
		border-bottom: 8px solid #3f4563;
	}
	.main-invoice-list li {
		border-bottom: 1px solid $white-1;
	}
	.invoice-print {
		border: 1px solid $white-1;
	}
	.border-right-0 {
		border-right: 0 !important;
	}
	.main-nav-line-email {
		border-bottom: 1px solid $white-1;
	}
	.bg-white {
		background-color: $dark-theme !important;
	}
	.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
		border-color: $white-1 $white-1 $white-1;
	}
	.option-dots:hover {
		background: $dark-theme2;
	}
	.profile-nav-line {
		border: 1px solid $white-1;
	}
	.tree li.branch {
		background: $dark-theme2;
		border-color: $white-1;
		li {
			background: $dark-theme;
			border-color: $white-1;
			.branch {
				background: $dark-theme2;
				border-color: $white-1;
			}
		}
	}
	.badge-light {
		color: $white-8;
		background-color:$dark-theme2;
	}
}
.dark-theme .select2-container--default .select2-search--inline .select2-search__field {
	color: #fff;
}
.dark-theme input:-internal-autofill-selected {
	background-color: $dark-theme !important;
	-webkit-text-fill-color: $white;
	-webkit-box-shadow: 0 0 0px 1000px $dark-theme inset;
	transition: background-color 5000s ease-in-out 0s;
	color: $white;
}
.dark-theme .latest-tasks .check-box .ckbox span:before {
	background-color: $dark-theme;
}
.dark-theme {
	.tabs-style-2 .main-nav-line .nav-link {
		background: #30385f;
	}
	.tabs-style-1 .main-nav-line .nav-link {
		background: $dark-theme2;
	}
	.tabs-style-3 {
		border: 1px solid rgba(227, 227, 227, 0.1);
		.nav.panel-tabs li a {
			background: rgba(239, 239, 245, 0.3);
			color: #fff;
		}
	}
	.tabs-style-4 .tabs-menu-body {
		border: 1px solid rgba(227, 227, 227, 0.15);
	}	
	.tabs-style-4 .nav.panel-tabs li a {
		background: $dark-theme3;
		color: #fff;		
	}
	.tab_wrapper {
		&.right_side .content_wrapper, &.left_side .content_wrapper, >ul li {
			border: 1px solid rgba(240, 242, 247, 0.12);
		}		
		&.right_side>ul li.active, &.left_side>ul li.active {
			border-color: rgba(240, 242, 247, 0.1);
		}
		&.right_side>ul, &.left_side>ul {
			border-bottom: 1px solid rgba(240, 242, 247, 0.1);
		}
	}
	.apexcharts-svg text{
		fill:$white-8;
	}
	
	.tx-gray-800 {
		color: $white-7;
	}
	.tx-gray-900 {
		color: $white-8;
	}
	ul.jq-input-dropdown{
		background:$dark-theme !important;
		color:$white !important;
		border: 1px solid rgba(240, 242, 247, 0.1);
		li {
			&:hover {
			color: $white;
			background-color: $dark-theme3;
		}
		}
	}
}
.dark-theme .tab_wrapper .content_wrapper .accordian_header.active {
	border: 1px solid rgba(240, 242, 247, 0.1);
}
.dark-theme .tab_wrapper .content_wrapper .accordian_header {
	border-top: 1px solid rgba(240, 242, 247, 0.1);
	border-bottom: 1px solid rgba(240, 242, 247, 0.1);
	.arrow {
		background: transparent;
		border-top: 3px solid rgba(240, 242, 247, 0.4);
		border-left: 3px solid rgba(240, 242, 247, 0.4);
	}
}