$background: #f4f5fd;
$default-color:#031b4e;

/*Color variables*/
$primary:#5066e0; 
$secondary:#8a4dd2;
$pink:#ff9ad5;
$teal:#00cccc;
$purple:#673ab7 ;
$success:#00d48f;
$warning:#ff8c00;
$danger:#fa5c7c;
$info:#02d7ff;
$orange:#fd7e14;
$dark:#3b4863;
$indigo:#ac50bb;
$white:#fff;
$black:#000;
$light:#f2f2f9;

/*gray variables*/
$gray-100:#f4f5fd;
$gray-200:#e3e6fa;
$gray-300:#d3d8f7;
$gray-400:#cbd0f5;
$gray-500:#b2bcf2;
$gray-600:#a3ade4;
$gray-700:#909ad4;
$gray-800:#757fb5;
$gray-900:#485079;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow:0 5px 15px 5px rgba(80, 102, 224, 0.08);

$dark-theme:#1e2448;
$dark-theme2:#16192f;
$dark-theme3:#181d3e;

$border:#edeef7;
$border2:#e5e6f3;

/*Colors*/
$primary2:#a306ec; 
$primary3:#2ec49f;
$primary4:#4aa9d5;
$primary5:#f96331;	
$primary6:#404980;	

